import { useMutation, useQuery } from "react-query";
import queryClient from "../../lib/queryClient";
import { GL, TDS } from "../types/gl";
import { useNotification } from "../../ui/utils/notification/notification2";
import {chainError, useDelete, useGet, usePatch, usePost} from "../util";

export const useGLs = (teamId: string) => {
  const get = useGet();
  return useQuery<GL[]>(["GLs", teamId], () =>
    get(`gl/?teamid=${teamId}`)
      .then((c) => (c === null ? [] : c))
      .catch(chainError("Error while fetching integrations"))
  );
};

export const useAddGL = (teamId: string) => {
  const notification = useNotification();
  const post = usePost();
  return useMutation<GL, Error, { gl: GL }>(
    ({gl}) =>
      post(`gl/?teamid=${teamId}`, gl).catch(
        chainError("Error while creating chart of account")
      ),
    {
      onSuccess: () => {
        notification.success("Chart of account created");
        queryClient.invalidateQueries(["GLs", teamId]);
        queryClient.invalidateQueries(["invoicesMetadata", teamId]);
      },
      onError: (error) => {
        notification.error(error.message);
      },
    }
  );
};

export const useUpdateGL = (teamId: string) => {
  const notification = useNotification();
  const patch = usePatch();
  return useMutation<GL, Error, {id: number, gl: GL }>(
    ({id, gl}) =>
      patch(`gl/${id}?teamid=${teamId}`, gl).catch(
        chainError("Error while updating chart of account")
      ),
    {
      onSuccess: () => {
        notification.success("Chart of account updated");
        queryClient.invalidateQueries(["GLs", teamId]);
        queryClient.invalidateQueries(["invoicesMetadata", teamId]);
      },
      onError: (error) => {
        notification.error(error.message);
      },
    }
  );
};

export const useDeleteGL = (teamId: string) => {
  const notification = useNotification();
  const req = useDelete();
  return useMutation<{ status: string }, Error, { gl: GL }>(
    ({ gl }) =>
      req(`gl/?teamid=${teamId}`, gl).catch(
        chainError("Error while deleting GL Code")
      ),
    {
      onSuccess: () => {
        notification.success("gl deleted");
        queryClient.invalidateQueries(["GLs", teamId]);
        queryClient.invalidateQueries(["invoicesMetadata", teamId]);
      },
      onError: (error) => {
        notification.error(error.message);
      },
      onSettled: () => {
        queryClient.invalidateQueries(["GLs", teamId]);
      },
    }
  );
};

export const useDeleteGLs = (teamId: string) => {
  const notification = useNotification();
  const req = useDelete();
  return useMutation<any, any, { gls: number[] }>(
    ({gls}) => {
      const list = gls.map((glId) =>
        req(`gl/${glId}?teamid=${teamId}`).catch(
          chainError("Error while deleting chart of account")
        )
      );
      return Promise.allSettled(list).then(() => Promise.all(list));
    },
    {
      onSuccess: () => {
        notification.success("Chart of accounts deleted");
        queryClient.invalidateQueries(["GLs", teamId]);
        queryClient.invalidateQueries(["invoicesMetadata", teamId]);
      },
      onError: (error) => {
        notification.error(error.message);
      },
      onSettled: () => {
        queryClient.invalidateQueries(["GLs", teamId]);
      },
    }
  );
};

export const useAddTDS = (teamId: string) => {
  const notification = useNotification();
  const post = usePost();
  return useMutation<TDS, Error, { tds: TDS }>(
    ({tds}) =>
      post(`tds/?teamid=${teamId}`, tds).catch(
        chainError("Error while creating tds")
      ),
    {
      onSuccess: () => {
        notification.success("TDS created");
        queryClient.invalidateQueries(["tds", teamId]);
        queryClient.invalidateQueries(["invoicesMetadata", teamId]);
      },
      onError: (error) => {
        notification.error(error.message);
      },
    }
  );
};

export const useUpdateTDS = (teamId: string) => {
  const notification = useNotification();
  const patch = usePatch();
  return useMutation<TDS, Error, {id: number, tds: TDS }>(
    ({id, tds}) =>
      patch(`tds/${id}?teamid=${teamId}`, tds).catch(
        chainError("Error while updating tds")
      ),
    {
      onSuccess: () => {
        notification.success("TDS updated");
        queryClient.invalidateQueries(["tds", teamId]);
        queryClient.invalidateQueries(["invoicesMetadata", teamId]);
      },
      onError: (error) => {
        notification.error(error.message);
      },
    }
  );
};

export const useTds = (currentTeam: string, invoiceId: string) => {
  const get = useGet();
  return useQuery<TDS[], Error>(["tds", invoiceId], () =>
    get(`tds/${invoiceId}?teamid=${currentTeam}`).catch(
      chainError("Error while getting tds details")
    )
  );
};

export const useTeamTds = (currentTeam: string) => {
  const get = useGet();
  return useQuery<TDS[], Error>(["tds", currentTeam], () =>
    get(`tds/?teamid=${currentTeam}`).catch(
      chainError("Error while getting tds details")
    )
  );
};

export const useDeleteTDS = (teamId: string) => {
  const notification = useNotification();
  const req = useDelete();
  return useMutation<any, any, { tdsIds: number[] }>(
    ({tdsIds}) => {
      const list = tdsIds.map((tdsId) =>
        req(`tds/${tdsId}?teamid=${teamId}`).catch(
          chainError("Error while deleting TDS")
        )
      );
      return Promise.allSettled(list).then(() => Promise.all(list));
    },
    {
      onSuccess: () => {
        notification.success("TDS deleted");
        queryClient.invalidateQueries(["tds", teamId]);
        queryClient.invalidateQueries(["invoicesMetadata", teamId]);
      },
      onError: (error) => {
        notification.error(error.message);
      }
    }
  );
};
