import { EMPTY_INVOICE } from "../../lib/constants/constants";
import { DOC_TYPE, Invoice } from "../../lib/types/invoice";

const commonAttrs: Invoice = { ...EMPTY_INVOICE };

export const INVOICES_MOCK: Invoice[] = [
  {
    Id: "27aa5323-dd63-4bad-a629-dca822c41370",
    FlowFileId: "",
    From_email: "info@nanonets.com",
    To_email: "info@nanonets.com",
    Status: "Approved",
    Url: "uploadedfiles/66f15cc5-2234-4289-825f-164dfd1c9f5d/RawPredictions/20489832-re-2022-12-09T13-21-50.497.jpg",
    Md5: "48bc1a3bfa756be92421ddb0ec92ed8b",
    Date: "2022-12-09T13:21:51Z",
    Extracted_vendor_address: "Johan På Gårdas Gata 5A\nS - 412 50 Göteborg",
    Extracted_vendor_name: "BlueStar Solutions AB",
    Extracted_vendor_email: "info@nanonets.com",
    Extracted_vendor_phone: "",
    Extracted_vendor_vat_number: "",
    Extracted_vendor_website: "",
    Extracted_doc_number: "89012",
    Extracted_doc_date: "2028-12-01",
    Final_doc_date: "2023-01-10",
    Extracted_due_date: "",
    Final_due_date: "2023-01-10",
    Extracted_amount: "2121",
    Extracted_currency: "",
    Extracted_buyer_name: "",
    Extracted_bank_name: "",
    Extracted_bank_account_number: "5106-5316",
    Extracted_bank_payto: "",
    DownloadPath:
      "https://nanonets.s3.us-west-2.amazonaws.com/uploadedfiles/66f15cc5-2234-4289-825f-164dfd1c9f5d/RawPredictions/20489832-re-2022-12-09T13-21-50.497.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256\u0026X-Amz-Credential=AKIA5F4WPNNTFQPJKAMK%2F20230218%2Fus-west-2%2Fs3%2Faws4_request\u0026X-Amz-Date=20230218T141357Z\u0026X-Amz-Expires=900\u0026X-Amz-SignedHeaders=host\u0026response-content-disposition=inline\u0026response-content-type=image%2Fjpg\u0026X-Amz-Signature=974a6a2c88a3ac51eae62198e9913e2fa703f9e4e8ee403900fb1dd3c3363910",
    PaymentDate: "",
    Paid_by: "",
    Email_sent: 1,
    Email_vendor_sent: 0,
    Slack_message_sent: 1,
    GLId: 0,
    CategoryId: 4,
    Email: "info@nanonets.com",
    ContentType: "image",
    Duplicate: 1,
    VendorId: 4,
    Frequency: "One-Time",
    InlineItems: [
      '{"Category":"","Description":"Avtalspris / kvartal : 4 290 SEK","Ymin":1614}',
      '{"Category":"","Description":"Tjänsteavtal , kvartalsvis debitering :\\nSlutkundspris / år : 34 320 SEK\\nSlutkundspris / kvartal : 8 580 SEK\\nAvtalspris / år : 17 160 SEK","Ymin":1419}',
      '{"Category":"","Description":"Tjänsteavtal U4IS / iCore Integration Suite\\n- Artikel nr 600 358\\nSlutkundspris : 143 000 SEK\\nAvtalspris : 71 500 SEK","Ymin":1217}',
    ],
    QbFlag: "export_success",
    ApproveRejectComment: "haha",
    InvoiceApprovers: [
      {
        id: 22,
        invoice_id: "27aa5323-dd63-4bad-a629-dca822c41370",
        approval_rule_id: "0",
        approver_id: "info@nanonets.com",
        status: "approved",
        approver_rank: 1,
        email_sent: true,
        approval_date: "2023 Jan 10",
        comment: "haha",
        name: "",
        action_source: "",
        is_mandatory: true,
      },
    ],
    Currency: "$",
    IsProcessing: false,
    IsArchived: false,
    Source: "ui",
    UpdatedAt: "2023-01-10T18:58:22Z",
    ...commonAttrs,
  },
  {
    Id: "d1613254-69e4-4ad4-ad1c-f388b16b149c",
    FlowFileId: "",
    From_email: "info@nanonets.com",
    To_email: "info@nanonets.com",
    Status: "Approved",
    Url: "uploadedfiles/66f15cc5-2234-4289-825f-164dfd1c9f5d/RawPredictions/138844554-re copy 3-2022-12-08T12-33-18.516.jpg",
    Md5: "847f5a925dc8514fde8757d5f75f99b4",
    Date: "2022-12-08T12:33:19Z",
    Extracted_vendor_address:
      "P.O. Box 12567\nLowe Supply\n4473 Burke Street\nCambridge , MA 02141-1222",
    Extracted_vendor_name: "Lowe Supply Co.",
    Extracted_vendor_email: "info@nanonets.com",
    Extracted_vendor_phone: "",
    Extracted_vendor_vat_number: "",
    Extracted_vendor_website: "",
    Extracted_doc_number: "12345321-11",
    Extracted_doc_date: "2018-12-24",
    Final_doc_date: "2018-12-24",
    Extracted_due_date: "",
    Final_due_date: "2022-12-08",
    Extracted_amount: "121.64",
    Extracted_currency: "",
    Extracted_buyer_name: "",
    Extracted_bank_name: "",
    Extracted_bank_account_number: "",
    Extracted_bank_payto: "",
    DownloadPath:
      "https://nanonets.s3.us-west-2.amazonaws.com/uploadedfiles/66f15cc5-2234-4289-825f-164dfd1c9f5d/RawPredictions/138844554-re%20copy%203-2022-12-08T12-33-18.516.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256\u0026X-Amz-Credential=AKIA5F4WPNNTFQPJKAMK%2F20230218%2Fus-west-2%2Fs3%2Faws4_request\u0026X-Amz-Date=20230218T141357Z\u0026X-Amz-Expires=900\u0026X-Amz-SignedHeaders=host\u0026response-content-disposition=inline\u0026response-content-type=image%2Fjpg\u0026X-Amz-Signature=fec3c3c90b2c280181d24a38ee3a88b424f896bd79fca3fdcc9c11d3b9f8d39c",
    PaymentDate: "",
    Paid_by: "",
    Email_sent: 1,
    Email_vendor_sent: 0,
    Slack_message_sent: 1,
    GLId: 2,
    CategoryId: 7,
    Email: "info@nanonets.com",
    ContentType: "image",
    Duplicate: 1,
    VendorId: 1,
    Frequency: "One-Time",
    InlineItems: [
      '{"Category":"2","Description":"R0012\\n1 PT PVC CEMENT 2246S 2246S","Line_Amount":"12.40","Price":"12.39765","Quantity":"1","Ymin":1116}',
      '{"Category":"2","Description":"R0904\\n2 PVC DWV P - TRAP","Line_Amount":"4.77","Price":"4.77","Quantity":"1","Ymin":1061}',
      '{"Category":"2","Description":"R0038\\n4 X 20 PVC DWV SCH 40 PIPE","Line_Amount":"44.78","Price":"223.91781","Quantity":"20","Ymin":893}',
      '{"Category":"2","Description":"R2854\\n05968 4 \\" DWV REPAIR COUPLING N / STOP","Line_Amount":"18.77","Price":"9.38707","Quantity":"2","Ymin":1283}',
      '{"Category":"2","Description":"R0854\\n4 PVC DWV COUPLING","Line_Amount":"2.79","Price":"2.79","Quantity":"1","Ymin":1227}',
      '{"Category":"2","Description":"R0002\\n1 PT PURPLE PRIMER 8746S","Line_Amount":"8.22","Price":"8.22","Quantity":"1","Ymin":1172}',
      '{"Category":"2","Description":"R0915\\n4 X 2 PVC DWV COMBY \\u0026 1/8","Line_Amount":"12.90","Price":"12.90","Quantity":"1","Ymin":1004}',
      '{"Category":"2","Description":"R0036\\n2 X 20 PVC DWV SCH 40 PIPE","Line_Amount":"17.01","Price":"85.06667","Quantity":"20","Ymin":949}',
    ],
    QbFlag: "export_success",
    ApproveRejectComment: "sdfas",
    InvoiceApprovers: [
      {
        id: 15,
        invoice_id: "d1613254-69e4-4ad4-ad1c-f388b16b149c",
        approval_rule_id: "0",
        approver_id: "info@nanonets.com",
        status: "approved",
        approver_rank: 2,
        email_sent: true,
        approval_date: "2022 Dec 08",
        comment: "sdfas",
        name: "",
        action_source: "",
        is_mandatory: true,
      },
    ],
    Currency: "$",
    IsProcessing: false,
    IsArchived: false,
    Source: "ui",
    UpdatedAt: "2022-12-08T18:14:24Z",
    Term: "45 days",
    TxnAmount: 1,
    ...commonAttrs,
  },
  {
    Id: "91a184c9-74c9-44e9-a922-354a985408cf",
    From_email: "shiva.bandari@nanonets.com",
    To_email: "shiva.bandari@nanonets.com",
    Status: "Fetched",
    Url: "uploadedfiles/005d6371-138f-4e2a-8425-7241015d8def/RawPredictions/193257422-re-2022-11-17T10-56-14.142.jpg",
    Md5: "9a0fe7f7f68fae008345783113dbedeb",
    Date: "2022-11-17T10:56:14Z",
    Extracted_vendor_address:
      "317 , Kohinoor Apt . Patel Estate Road\nJogeshwari , Chennai - 98",
    Extracted_vendor_name: "AN Furnishing Technologies",
    Extracted_vendor_email: "Finishingtechnologies@yahoo.co.in",
    Extracted_vendor_phone: "",
    Extracted_vendor_vat_number: "",
    Extracted_vendor_website: "",
    Extracted_doc_number: "3210 / 19-20",
    Extracted_doc_date: "2019-12-07",
    Final_doc_date: "2019-12-07",
    Extracted_due_date: "",
    Final_due_date: "",
    Extracted_amount: "2835.54",
    Extracted_currency: "Indian Rupees",
    Extracted_buyer_name: "",
    Extracted_bank_name: "AHJ Bank",
    Extracted_bank_account_number: "06872000001220",
    Extracted_bank_payto: "",
    DownloadPath:
      "https://nanonets.s3.us-west-2.amazonaws.com/uploadedfiles/005d6371-138f-4e2a-8425-7241015d8def/RawPredictions/193257422-re-2022-11-17T10-56-14.142.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256\u0026X-Amz-Credential=AKIA5F4WPNNTFQPJKAMK%2F20221117%2Fus-west-2%2Fs3%2Faws4_request\u0026X-Amz-Date=20221117T121930Z\u0026X-Amz-Expires=900\u0026X-Amz-SignedHeaders=host\u0026response-content-disposition=inline\u0026response-content-type=image%2Fjpg\u0026X-Amz-Signature=dcaa836e0078f3b896bc01b0acea0583063515e61563dba565171bbcf6d50269",
    PaymentDate: "",
    Paid_by: "",
    Email_sent: 1,
    Email_vendor_sent: 0,
    Slack_message_sent: 1,
    GLId: 0,
    Email: "shiva.bandari@nanonets.com",
    ContentType: "image",
    Duplicate: 1,
    VendorId: 63,
    Comments: "",
    Frequency: "One-Time",
    InlineItems: [
      '{"Description":"CGST", "Account":"014e7fbd-ba0c-4c56-abda-e86b62c38ea6","Line_Amount":"216.27","Price":"","Product_Code":"","Quantity":""}',
      '{"Description":"SF1563 6MM PUSHFIT COLLETion", "Account":"e86b62c38ea6","Line_Amount":"2,403.00","Price":"1.80","Product_Code":"38101020","Quantity":"1,335.000 NOS"}',
      '{"Description":"SF1563 6MM PUSHFIT COLLETion", "Account":"014e7fbd-ba0c-4c56-abda-e86b62c38ea6","Line_Amount":"2,403.00","Price":"1.80","Product_Code":"38101020","Quantity":"1,335.000 NOS"}',
    ],
    Currency: "Indian Rupees",
    Source: "ui",
    UpdatedAt: "2022-11-17T10:58:33Z",
    Class: "Kirsville",
    Project: "Nanonets",
    PaymentMethod: 0,
    PaymentSource: "internal",
    Term: "NET 15",
    Memo: "This is an invoice",
    TaxCode: "VAT",
    CreditCardNumber: "1234",
    DocType: DOC_TYPE.INVOICE,
    ClientId: "4566",
    ...commonAttrs,
  },
];

export const labelsFromNanonets = [
  {
    label: "location",
    email: "mverbanac@sopriscapital.com",
    displayName: "Location",
    type: "field",
  },
  {
    label: "item_name",
    email: "mverbanac@sopriscapital.com",
    displayName: "Item Name",
    type: "field",
  },
  {
    label: "GL_name",
    email: "mverbanac@sopriscapital.com",
    displayName: "GL Name",
    type: "field",
  },
  {
    label: "GL_Code",
    email: "accounts@holmanbarnesgroup.com.au",
    displayName: "GL_Code",
    type: "table_column",
  },
];
