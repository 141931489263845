import React, {createContext, useContext, useState} from 'react';

const InvoiceRowsState = () => useState<string[]>([]);

const InvoiceRowsContext = createContext<ReturnType<typeof InvoiceRowsState>>([[], null])

const useInvoiceRows = () => {
  const context = useContext(InvoiceRowsContext);
  if (!context) throw new Error('useInvoiceRows must be used within the context');
  return context;
};

export const InvoiceRowsProvider = ({children}) => {
  const value = InvoiceRowsState();
  return <InvoiceRowsContext.Provider value={value}>
    {children}
  </InvoiceRowsContext.Provider>
}

export default useInvoiceRows