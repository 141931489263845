import { useAuth0 } from "../react-auth0-spa";
import { useMutation, useQuery } from "react-query";
import queryClient from "../../lib/queryClient";
import { useNotification } from "../../ui/utils/notification/notification2";
import { AccountPlan, UserDetails } from "../types/user";
import { chainError, useGet } from "../util";
import { TeamUserRole } from "../types/team";
import { FeatureFlags } from "../types/featureFlag";
import {useHistory} from "react-router-dom";

export const useTeam = (currentTeam: string) => {
  const get = useGet();
  const history = useHistory()
  return useQuery<UserDetails, Error>(["teamdetails", currentTeam], () =>
    get(`teamdetails?teamid=${currentTeam}`).catch(
      (err) => {
        if (err?.message === "failed to create new nanonets user or models\n") {
          history.push(`/error/failed-to-create-free-model/?teamId=${currentTeam}`)
        }
        return chainError("Error while fetching team")()
      }
    )
  );
};

export const useFeatureFlags = (teamId: string) => {
  const get = useGet();
  return useQuery<FeatureFlags, Error>(["featureflags", teamId], () =>
    get(`featureflags?teamid=${teamId}`).catch(
      chainError("Error while fetching team feature flags")
    )
  );
};

export const useIsPro = (teamId: string) => {
  const userQuery = useTeam(teamId);
  const isPro = userQuery.data?.plan !== AccountPlan.PLAN_FREE;
  return {
    ...userQuery,
    data: isPro,
    isPro,
  };
};

export const useUserRole = (teamId: string) => {
  const get = useGet();
  return useQuery<TeamUserRole, Error>(["userRole", teamId], () =>
    get(`getuserrole?teamid=${teamId}`).catch(
      chainError("Error while fetching user role in team")
    )
  );
};

export const useStripeKey = (teamId: string) => {
  const get = useGet();
  return useQuery<any, Error>(["stripeKey", teamId], () =>
    get(`getstripekey?teamid=${teamId}`).catch(
      chainError("Error while fetching user role in team")
    )
  );
};

export const useStripeBalance = (teamId) => {
  const { getTokenSilently } = useAuth0();
  return useQuery<any, Error>(["stripeBalance", teamId], async () => {
    const token = await getTokenSilently();
    const response = await fetch(`/api/v1/stripe/balance?teamid=${teamId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error("Error while fetching stripe account balance");
    } else {
      const data = await response.json();
      return data;
    }
  });
};

export const useUpdateUserVendorStatus = () => {
  const notification = useNotification();
  const { getTokenSilently } = useAuth0();
  return useMutation<any, Error, { status: string }>(
    async ({ status }) => {
      const token = await getTokenSilently();
      return fetch(`/api/v1/invoices/updatesellerstatus`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ status: status }),
      }).then((res) =>
        res.ok
          ? res.json()
          : Promise.reject(new Error("Error while updating user status"))
      );
    },
    {
      onSuccess: (data) => {
        console.log("1", data);
        notification.success("user updated");
        queryClient.setQueriesData(["teamdetails"], (prevData) => {
          return data;
        });
      },
      onError: (error) => {
        console.log("2");
        notification.error(error.message);
      },
    }
  );
};

export const useUpdateTeamMetadata = (teamId) => {
  const notification = useNotification();
  const { getTokenSilently } = useAuth0();
  return useMutation<any, Error, any>(
    async (request) => {
      const token = await getTokenSilently();
      return fetch(`/api/v1/team/?teamid=${teamId}`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
      }).then((res) =>
        res.ok
          ? res.json()
          : Promise.reject(new Error("Error while updating user status"))
      );
    },
    {
      onSuccess: (data) => {
        notification.success("Settings updated");
        queryClient.setQueriesData(["teamdetails", teamId], (prevData) => {
          return data;
        });
      },
      onError: (error) => {
        notification.error(error.message);
      },
    }
  );
};

export const useUpdateUserForwardedEmail = (teamId) => {
  const notification = useNotification();
  const { getTokenSilently } = useAuth0();
  return useMutation<any, Error, { forwardedEmail: string }>(
    async ({ forwardedEmail }) => {
      const token = await getTokenSilently();
      return fetch(`/api/v1/updateforwardedemail?teamid=${teamId}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(forwardedEmail),
      }).then((res) =>
        res.ok
          ? res.json()
          : Promise.reject(
              new Error("Error while updating user forwarded email")
            )
      );
    },
    {
      onSuccess: (data) => {
        notification.success("Changes saved successfully");
        queryClient.setQueriesData(["teamdetails"], (prevData) => {
          return data;
        });
      },
      onError: (error) => {
        notification.error(error.message);
      },
    }
  );
};

export const useUpdateStripeKey = (teamId) => {
  const notification = useNotification();
  const { getTokenSilently } = useAuth0();
  return useMutation<any, Error, { stripeKey: string }>(
    async ({ stripeKey }) => {
      const token = await getTokenSilently();
      return fetch(`/api/v1/updatestripekey?teamid=${teamId}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(stripeKey),
      }).then((res) =>
        res.ok
          ? res.json()
          : Promise.reject(new Error("Error while updating stripe key"))
      );
    },
    {
      onSuccess: (data) => {
        notification.success("Changes saved successfully");
        queryClient.invalidateQueries(["stripeKey", teamId]);
      },
      onError: (error) => {
        notification.error(error.message);
      },
    }
  );
};

export const useLoggedInUser = () => {
  const {user} = useAuth0();
  return {
    email: user?.email || ""
  }
}