import React, {FC} from "react";
import classes from "./ConvertToPro.module.scss";
import Button from "@mui/material/Button";
import {useAccessControl} from "../../../utils/access/access";
import {ACCESS_FEATURE} from "../../../lib/types/access";
import {useTeam} from "../../../api/queries/user";
import {useUrlSearchParams} from "../../../utils/common/url";
import {getRemainingTrailDays} from "../../../utils/common/date";

const getDaysLeftMessage = (days: number) => {
  switch (days) {
    case 1:
      return "1 Day Left!"
    default:
      return `${days} Days Left!`
  }
}

export const TalkToUs: FC = () => {
  return <>
      <Button
        color="primary"
        size="small"
        onClick={() => {
          window
            .open("https://calendly.com/nanonets/flow", "_blank")
            .focus();
        }}
      >
        Talk to us
      </Button>
      to explore paid plans and get full access to invoice and receipt processing, integrations and payments.
  </>;
};

const ConvertProCTA: FC = () => {
  const {teamId} = useUrlSearchParams();
  const {data: teamUser} = useTeam(teamId);

  const hasAccess = useAccessControl();
  const showUpgradeButton = hasAccess(ACCESS_FEATURE.CONVERT_TO_PRO);

  if (!showUpgradeButton) {
    return <div/>
  }

  return <div className={classes.proNotice}>
    <div>
      {getDaysLeftMessage(getRemainingTrailDays(teamUser?.createdAt))}
      <TalkToUs/>
    </div>
  </div>;
};

export default ConvertProCTA
