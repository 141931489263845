import { useMutation, useQuery } from "react-query";
import { useNotification } from "../../../ui/utils/notification/notification2";
import { chainError, useGet, useRawRequest } from "../../util";
import { ExportHistory } from "../../types/export";

export const useDownloadExcel = (teamId) => {
  const get = useRawRequest({ parseFn: (r) => r.blob() });
  const notification = useNotification();

  return useMutation<string, Error, { ids: string[] }>(
    ({ ids }) =>
      get(
        `invoices/downloadExcel/?teamid=${teamId}${ids
          .map((id) => `&id=${id}`)
          .join("")}`
      ),
    {
      onSuccess: (response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "download.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        notification.success("Downloaded successfully");
      },
      onError: (error) => {
        notification.error(error.message);
      },
    }
  );
};

const sortExportHistory = (exportHistory: ExportHistory[]) => {
  return (
    exportHistory?.sort((e2, e1) => {
      return (
        new Date(e1.createdAt).getTime() - new Date(e2.createdAt).getTime()
      );
    }) || []
  );
};

export const useExportHistory = (invoiceId: string, teamId: string) => {
  const get = useGet();
  return useQuery<ExportHistory[], Error>(["exportHistory", invoiceId], () =>
    get(`invoices/${invoiceId}/exportHistory/?teamid=${teamId}`)
      .then(sortExportHistory)
      .catch(chainError("Error while fetching export history"))
  );
};
