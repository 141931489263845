import { useMutation, useQuery } from "react-query";
import { GLS_MOCK } from "../constants/gl";
import { GL, TDS } from "../types/gl";
import { defaultMutationFn } from "../util";

export const useGLs = (ownerEmail) => {
  return useQuery<GL[]>(["GLs", ownerEmail], () => GLS_MOCK);
};

export const useTds = (ownerEmail) => {
  return useQuery<TDS[]>(["tds", ownerEmail], () => []);
};

export const useAddGL = (ownerEmail) => {
  return useMutation<GL, Error, { gl: GL }>(
    defaultMutationFn
  );
};

export const useDeleteGL = (ownerEmail) => {
  return useMutation<any, Error, { gl: GL }>(defaultMutationFn);
};
