import { Download } from "@mui/icons-material";
import { Button } from "@mui/material";
import React, { FC } from "react";
import { useBooleanState } from "../../utils/common/state";
import Header from "../Approvals/Header/Header";
import classes from "./Analytics.module.scss";
import AnalyticsTable from "./AnalyticsTable/AnalyticsTable";
import FilterSelection from "./FilterSelection/FilterSelection";
import SpendsGraph from "./SpendsGraph/SpendsGraph";
import { useAnalyticsPageReducer } from "./state";
import { useAnalyticsPageUrlEffect } from "./url";
import CurrentSpendTile from "./SpendTiles/CurrentSpendTile";
import CurrentDueTile from "./SpendTiles/CurrentDueTile";
import { useUiConfig } from "../../ui/utils/config/config";
import { useTeamId } from "../../utils/team";

const AnalyticsPage: FC = () => {
  const [state, dispatch] = useAnalyticsPageReducer();
  useAnalyticsPageUrlEffect(state);
  const [isDownloaderOpen, openDownloader, closeDownloader] = useBooleanState();
  const teamId = useTeamId()
  const [baseCurrency = teamId === "payments@uni.club" ?  "₹" : "$", setBaseCurrency] = useUiConfig<string>("baseCurrency");

  return (
    <div className={classes.root}>
      <Header
        title="Analytics"
        desc="Track & manage your spending with ease. Overview of your spending
        by category, document type and more."
        actions={
          <Button
            startIcon={<Download />}
            variant="contained"
            onClick={openDownloader}
          >
            Export Data
          </Button>
        }
      />
      <div className={classes.content}>
        <div className={classes.tiles}>
          <CurrentSpendTile  baseCurrency={baseCurrency}/>
          <CurrentDueTile  baseCurrency={baseCurrency} />
        </div>
        <SpendsGraph filters={state.filters} baseCurrency={baseCurrency} />
        <FilterSelection
          state={state}
          dispatch={dispatch}
          baseCurrency={baseCurrency}
          setBaseCurrency={setBaseCurrency}
        />
        <AnalyticsTable
          filters={state.filters}
          isDownloaderOpen={isDownloaderOpen}
          closeDownloader={closeDownloader}
        />
      </div>
    </div>
  );
};

export default AnalyticsPage;
