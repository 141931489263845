import React, { MouseEventHandler } from "react";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Avatar,
  AvatarGroup,
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  styled,
  Tooltip,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  AccessTimeFilledRounded,
  ArchiveRounded,
  ErrorRounded,
  MailOutline,
  MoreHoriz,
  RestoreRounded,
  Star,
  StarBorder,
  UnarchiveRounded,
  WarningRounded,
} from "@mui/icons-material";
import classes from "./InvoiceTable.module.scss";
import { ExpandMore, LocalOfferSharp, Warning } from "@mui/icons-material";
import {
  FLOW_SCREEN,
  INVOICE_LIFECYCLE_STAGE,
  INVOICE_STATUS,
} from "../../../lib/constants/constants";
import {
  getFormattedDate,
  getStatus,
  stringToColor,
} from "../../../utils/util";
import tooltipClasses from "../../../lib/styles/customizations/Tooltip.module.scss";
import { InvoiceRow } from "./grid";
import moment from "moment";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { FeatureFlags } from "../../../api/types/featureFlag";
import { DOC_TYPE } from "../../../lib/types/invoice";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 250,
      maxWidth: 250,
      borderRadius: "10px",
    },
  },
};

const iconComponent = (props) => {
  return (
    <ExpandMore
      {...props}
      className={classes.expand}
      style={{
        fontSize: "16px",
        color: "rgba(173, 178, 194, 0.5019607843)",
        marginRight: 5,
      }}
    />
  );
};

export const renderStarCell = ({ params, onStarClick }) => {
  return (
    <Tooltip
      title={params?.row?.data?.Starred ? "De-Prioritise" : "Prioritise"}
      placement="top"
      classes={tooltipClasses}
    >
      <IconButton
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          onStarClick(params?.row?.id, params?.row?.data?.Starred);
        }}
      >
        {params?.row?.data?.Starred ? <Star /> : <StarBorder />}
      </IconButton>
    </Tooltip>
  );
};

export const renderActionsCell = ({
  params,
  onDownloadRow,
  onDeleteRow,
  invoiceAccess,
  adminAccess,
  screen,
  onSingleInvoiceArchivalClick,
  onSingleRestoreFilesClick,
}) => {
  const onDownloadClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    onDownloadRow(params?.row?.id);
  };
  const onDeleteClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    onDeleteRow(params?.row?.id);
  };
  const onArchiveActionClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    onSingleInvoiceArchivalClick(params?.row?.id);
  };
  const onRestoreActionClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    onSingleRestoreFilesClick(params?.row?.id);
  };
  return (
    <>
      <IconButton className={classes.more} size="small">
        <MoreHoriz />
      </IconButton>
      <IconButton
        disableRipple
        className={
          (params?.row?.data?.Status === INVOICE_STATUS.PAID ||
            params?.row?.data?.Status === INVOICE_STATUS.REJECTED ||
            screen === FLOW_SCREEN.TRASH) &&
          adminAccess
            ? classes.actionStyle2
            : adminAccess
            ? classes.action
            : classes.actionStyle3
        }
        size="small"
        onClick={onDownloadClick}
      >
        <Tooltip title="Download" placement="top" classes={tooltipClasses}>
          <DownloadRoundedIcon />
        </Tooltip>
      </IconButton>
      {(params?.row?.data?.Status === INVOICE_STATUS.PAID ||
        params?.row?.data?.Status === INVOICE_STATUS.REJECTED) &&
        adminAccess &&
        screen !== FLOW_SCREEN.TRASH && (
          <IconButton
            disableRipple
            className={classes.actionStyle2}
            size="small"
            onClick={onArchiveActionClick}
          >
            {screen === FLOW_SCREEN.ARCHIVE ? (
              <Tooltip
                title="Unarchive"
                placement="top"
                classes={tooltipClasses}
              >
                <UnarchiveRounded />
              </Tooltip>
            ) : (
              <Tooltip title="Archive" placement="top" classes={tooltipClasses}>
                <ArchiveRounded />
              </Tooltip>
            )}
          </IconButton>
        )}

      {screen === FLOW_SCREEN.TRASH && adminAccess && (
        <IconButton
          disableRipple
          className={classes.actionStyle2}
          size="small"
          onClick={onRestoreActionClick}
        >
          <Tooltip title="Restore" placement="top" classes={tooltipClasses}>
            <RestoreRounded />
          </Tooltip>
        </IconButton>
      )}

      <IconButton
        hidden={
          !invoiceAccess ||
          (screen === FLOW_SCREEN.TRASH && !adminAccess) ||
          (screen === FLOW_SCREEN.ARCHIVE && !adminAccess)
        }
        disableRipple
        className={
          params?.row?.data?.Status === INVOICE_STATUS.PAID ||
          params?.row?.data?.Status === INVOICE_STATUS.REJECTED ||
          screen === FLOW_SCREEN.TRASH
            ? classes.actionStyle2
            : classes.action
        }
        size="small"
        onClick={onDeleteClick}
      >
        <Tooltip
          title={screen === FLOW_SCREEN.TRASH ? "Delete" : "Move to trash"}
          classes={tooltipClasses}
          placement="top"
        >
          <DeleteIcon />
        </Tooltip>
      </IconButton>
    </>
  );
};

export const renderCategoryCell = ({
  params,
  categoryOptions,
  handleChange,
  invoiceAccess,
  readonly,
}) => {
  const onCategoryClickFromRow = (e) => {
    handleChange(params?.id, e?.target?.value);
  };

  return (
    <FormControl variant="standard">
      <Select
        sx={{
          // bgcolor: "rgb(250,250,250)",
          borderRadius: 1,
          padding: 1,
        }}
        disableUnderline
        IconComponent={iconComponent}
        disabled={!invoiceAccess || readonly}
        className={classes.selectInput}
        id="gl-select"
        value={
          params?.row?.data?.CategoryId === 0
            ? ""
            : params?.row?.data?.CategoryId
        }
        onChange={onCategoryClickFromRow}
        displayEmpty
        renderValue={
          params?.row?.data?.CategoryId !== 0 &&
          categoryOptions?.find((c) => c.id === params?.row?.data?.CategoryId)
            ? undefined
            : () => (
                <span>
                  <LocalOfferSharp /> Unassigned
                </span>
              )
        }
        MenuProps={MenuProps}
      >
        {categoryOptions?.map((o) => {
          return (
            <MenuItem
              value={o["id"]}
              className={classes.selectInput}
              key={o["id"]}
            >
              <span className="glIcon">
                <LocalOfferSharp
                  style={{
                    color: "#adb2c280",
                    fontSize: 16,
                    transform: "rotateY(180deg)",
                  }}
                />{" "}
                {o["name"]}
              </span>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export const renderGLCodeCell = ({
  params,
  glOptions,
  handleChange,
  invoiceAccess,
  readonly,
}) => {
  const onGLCodeClickFromRow = (e) => {
    handleChange(params?.id, e?.target?.value);
  };

  return (
    <FormControl variant="standard">
      <Select
        sx={{
          // bgcolor: "rgb(250,250,250)",
          borderRadius: 1,
          padding: 1,
        }}
        disableUnderline
        IconComponent={iconComponent}
        disabled={!invoiceAccess || readonly}
        className={classes.selectInput}
        id="gl-select"
        value={params?.row?.data?.GLId === 0 ? "" : params?.row?.data?.GLId}
        onChange={onGLCodeClickFromRow}
        displayEmpty
        renderValue={
          params?.row?.data?.GLId !== 0 &&
          glOptions?.find((c) => c.id === params?.row?.data?.GLId)
            ? () => {
              const gl = glOptions?.find((c) => c.id === params?.row?.data?.GLId);
              return (
                <span className="glIcon">
                  <LocalOfferSharp
                    style={{
                      color: "#adb2c280",
                      fontSize: 16,
                      transform: "rotateY(180deg)",
                    }}
                  />{" "}
                  {gl["name"]}
                </span>
              )
            }
            : () => (
                <span>
                  <LocalOfferSharp /> Unassigned
                </span>
              )
        }
        MenuProps={MenuProps}
      >
        {glOptions?.filter(c => !c.isOld)?.map((o) => {
          return (
            <MenuItem
              value={o["id"]}
              className={classes.selectInput}
              key={o["id"]}
            >
              <span className="glIcon">
                <LocalOfferSharp
                  style={{
                    color: "#adb2c280",
                    fontSize: 16,
                    transform: "rotateY(180deg)",
                  }}
                />{" "}
                {o["name"]}
              </span>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export const checkCellClassBasedOnValue = ({ value = "" }) => {
  if (value === "No vendor assigned") {
    return classes.emailCell;
  } else {
    return classes.vendorCell;
  }
};

export const renderTotalDueCell = (params) => {
  const val =
    params?.row?.data?.Currency + " " + params?.row?.data?.Extracted_amount;
  return (
    <Tooltip title={val} placement="top" classes={tooltipClasses}>
      <div>{val}</div>
    </Tooltip>
  );
};

export const renderTotalHTCell = (params) => {
  const val =
    params?.row?.data?.Currency + " " + params?.row?.data?.CustomTotalHT;
  return (
    <Tooltip title={val} placement="top" classes={tooltipClasses}>
      <div>{val}</div>
    </Tooltip>
  );
};

export const renderTVACell = (params) => {
  const val = params?.row?.data?.Currency + " " + params?.row?.data?.CustomTVA;
  return (
    <Tooltip title={val} placement="top" classes={tooltipClasses}>
      <div>{val}</div>
    </Tooltip>
  );
};

export const renderVendorNameCell = (params) => {
  if (params?.row?.data?.Duplicate === 1) {
    return (
      <Tooltip
        title="Duplicate Invoice"
        placement="top"
        classes={tooltipClasses}
      >
        <span>
          <Warning fontSize="small" style={{ color: "red", marginTop: "-5px" }} /> {params?.value}
        </span>
      </Tooltip>
    );
  } else {
    return <span>{params?.value}</span>;
  }
};

export const renderBuyerNameCell = (params) => {
  return <span>{params?.value}</span>;
};

export const renderClientIdCell = (params) => {
  return <span>{params?.value}</span>;
};

export const renderSentByCell = ({ value = "" }) => {
  return (
    <Tooltip
      title={
        <div className={tooltipClasses.title}>
          <Avatar
            style={{
              height: 12,
              width: 12,
              background: stringToColor(value),
            }}
          />
          {value}
        </div>
      }
      placement="top"
      classes={tooltipClasses}
    >
      <Avatar
        style={{
          height: 24,
          width: 24,
          background: stringToColor(value),
        }}
      >
        <AvatarText>{value?.charAt(0).toUpperCase()}</AvatarText>
      </Avatar>
    </Tooltip>
  );
};

export const renderStatusCell = (
  params: GridRenderCellParams<string, InvoiceRow, string>,
  featureFlags: FeatureFlags
) => {
  return (
    <>
      <div>{getStatus(params?.value)}</div>
      {params?.row?.data?.DocType === DOC_TYPE.INVOICE &&
        (
          [
            INVOICE_LIFECYCLE_STAGE.ACTIVE,
            INVOICE_LIFECYCLE_STAGE.ARCHIVED,
          ] as string[]
        ).includes(params?.row?.data?.LifecycleStage) && (
          <>
            {!featureFlags?.senderAsVendor &&
              params?.row?.vendor &&
              !params?.row?.vendor.email && (
                // Vendor exists but email doesn't
                <Tooltip
                  title={
                    "Your vendor isn't currently receiving updates on this " +
                    params?.row?.data?.DocType +
                    ". Add an email ID for this vendor to enable this."
                  }
                  placement="top"
                  classes={tooltipClasses}
                >
                  <WarningRounded color="warning" style={{ fontSize: 16 }} />
                </Tooltip>
              )}

            {params?.row?.data.NudgedByVendor && (
              <Tooltip
                title="Your vendor has requested for an update on this invoice."
                placement="top"
                classes={tooltipClasses}
              >
                <MailOutline color="info" style={{ fontSize: 16 }} />
              </Tooltip>
            )}
          </>
        )}
    </>
  );
};

export const renderDueDateCell = (params) => {
  return (
    <>
      <div>{getFormattedDate(params?.row?.data?.Final_due_date)}</div>
      {params?.row?.data?.ScheduledPaymentStatus === "SCHEDULED" && (
        <Tooltip
          title={`This invoice has been scheduled for payment on ${moment
            .utc(params?.row?.data?.PaymentScheduledAt)
            .local()
            .format("Do MMM YYYY, hh:mm a")}`}
          placement="top"
          classes={tooltipClasses}
        >
          <AccessTimeFilledRounded
            color="warning"
            style={{ fontSize: 16, marginLeft: "4px" }}
          />
        </Tooltip>
      )}
      {params?.row?.data?.ScheduledPaymentStatus === "FAILED" && (
        <Tooltip
          title="This scheduled payment was triggered but not completed successfully. Please check your Wise account for more details."
          placement="top"
          classes={tooltipClasses}
        >
          <ErrorRounded
            color="error"
            style={{ fontSize: 16, marginLeft: "4px" }}
          />
        </Tooltip>
      )}
    </>
  );
};

export const renderApproverCell = ({ id, value, onApproverClick }) => {
  const onApproverClickFromRow = (e) => {
    e.stopPropagation();
    onApproverClick(id, e);
  };

  return (
    <>
      {value?.length > 0 ? (
        <Button
          color="inherit"
          style={{ background: "unset" }}
          onClick={onApproverClickFromRow}
        >
          <AvatarGroup max={3} classes={{ avatar: classes.avatarStyle }}>
            {value?.map((approvers) => (
              <Avatar
                key={approvers?.approver_id}
                style={{
                  height: 24,
                  width: 24,
                  background: stringToColor(approvers?.approver_id),
                }}
              >
                <AvatarText>
                  {approvers?.approver_id?.charAt(0).toUpperCase()}
                </AvatarText>
              </Avatar>
            ))}
          </AvatarGroup>
        </Button>
      ) : (
        <Tooltip
          title="You will need to assign an approver before this invoice can be paid. Click on the invoice to assign an approver."
          arrow
          placement="bottom"
        >
          <Button color="inherit" style={{ background: "unset" }}>
            <Avatar
              style={{
                height: 24,
                width: 24,
                background: "grey",
              }}
            />
          </Button>
        </Tooltip>
      )}
      {value?.length > 0 && (
        <KeyboardArrowDownIcon
          style={{
            fontSize: "16px",
            color: "rgba(173, 178, 194, 0.5019607843)",
          }}
          className={classes.expand}
        />
      )}
    </>
  );
};

export const AvatarText = styled("span")({
  color: "#fff",
  fontSize: 12,
});
