import { useMutation, useQuery } from "react-query";
import { defaultMutationFn } from "../util";
import { Invoices } from "../types/invoice";
import { Invoice, PaymentMethod } from "../../lib/types/invoice";
import { InvoiceApprover } from "../../lib/types/approval";
import { PaymentsWrapper } from "../../lib/types/payment";
import { INVOICES_MOCK } from "../constants/invoice";
import { QB_TAXCODES_MOCK } from "../constants/qb";
import { QbTaxCode } from "../types/qb";
import { INVOICES_METADATA_MOCK } from "../constants/metadata";
import { InvoicesMetadata } from "../types/metadata";

export const useSelectedInvoices = (invoiceIds: string[], teamId: string) => {
  return useQuery<Invoice[], Error>(
    ["invoiceList.mock", invoiceIds.sort().join()],
    () => []
  );
};

export const useInvoice = (invoiceId, currentTeam) => {
  return useQuery<any, Error>(
    ["invoice.mock", invoiceId],
    () => INVOICES_MOCK[0]
  );
};

export const useInvoices = (currentTeam, params, enabled = true) => {
  return useQuery<Invoices, Error>(
    ["invoices.mock", currentTeam, params],
    () => ({
      forwardEmail: "",
      invoices: INVOICES_MOCK,
    })
  );
};

export const useInvoicesMetadata = (teamId: string) => {
  return useQuery<InvoicesMetadata, Error>(
    ["invoicesMetadata", teamId],
    () => INVOICES_METADATA_MOCK
  );
};

export const usePatchInvoice = (currentTeam) => {
  return useMutation<any, any, any>(defaultMutationFn);
};

export const usePatchSingleInvoice = (currentTeam) => {
  return useMutation<any, any, any>(defaultMutationFn);
};

export const useUpdateInvoiceStatus = (currentTeam, id) => {
  return useMutation<any, any, any>(defaultMutationFn);
};

export const useSendPoToVendor = (teamId: string) => {
  return useMutation<void, Error, string>(defaultMutationFn);
};

export const useApproveInvoice = (currentTeam, invoiceId) => {
  return useMutation<any, any, any>(defaultMutationFn);
};

export const useRejectInvoice = (currentTeam, invoiceId) => {
  return useMutation<any, any, any>(defaultMutationFn);
};

export const useUndoApproval = (currentTeam, invoiceId) => {
  return useMutation<any, any, any>(defaultMutationFn);
};

export const useFetchUsdAmount = (currentTeam) => {
  return useMutation<any, any, any>(defaultMutationFn);
};

export const useDeleteInvoice = (currentTeam) => {
  return useMutation<any, any, any>(defaultMutationFn);
};

export const usePaymentMethods = (ownerEmail) => {
  return useQuery<PaymentMethod[]>(
    ["paymentMethods.mock", ownerEmail],
    () => []
  );
};

export const useAddPaymentMethod = (ownerEmail) => {
  return useMutation<any, any, any>(defaultMutationFn);
};

export const usePayInvoiceViaWise = (currentTeam) => {
  return useMutation<any, any, any>(defaultMutationFn);
};

export const useInvoiceApprovers = (invoiceId, currentTeam) => {
  return useQuery<InvoiceApprover[], Error>(
    ["invoiceApprovers", invoiceId],
    () => []
  );
};

export const useUpdateInvoiceApprovers = (currentTeam) => {
  return useMutation<any, any, any>(defaultMutationFn);
};

export const useUpdateInvoiceApprover = (currentTeam, invoiceId) => {
  return useMutation<any, any, any>(defaultMutationFn);
};

export const useAddInvoiceApprover = (currentTeam, invoiceId) => {
  return useMutation<any, any, any>(defaultMutationFn);
};

export const useDeleteInvoiceApprover = (currentTeam, invoiceId) => {
  return useMutation<any, any, any>(defaultMutationFn);
};

export const useRecipients = (currentTeam, currency) => {
  return useQuery<any, Error>(
    ["recipients.mock", currentTeam, currency],
    () => []
  );
};

export const useDeleteIntegration = (teamId) => {
  return useMutation<any, any, any>(defaultMutationFn);
};

export const useQbProjects = (currentTeam) => {
  return useQuery<any, Error>(["qbProjects.mock", currentTeam], () => []);
};

export const useQbClasses = (currentTeam) => {
  return useQuery<any, Error>(["qbClasses.mock", currentTeam], () => []);
};

export const useQbTerms = (currentTeam) => {
  return useQuery<any, Error>(["qbTerms.mock", currentTeam], () => []);
};

export const useQbTaxCodes = (currentTeam) => {
  return useQuery<QbTaxCode[], Error>(
    ["qbTaxCodes.mock", currentTeam],
    () => QB_TAXCODES_MOCK
  );
};

export const useDuplicateInvoices = (invoiceId, currentTeam) => {
  return useQuery<any, Error>(["duplicates.mock", invoiceId], () => []);
};

export const usePayments = (invoiceId: string, ownerEmail: string) => {
  return useQuery<PaymentsWrapper, Error>(["payments.mock", invoiceId], () => ({
    dueAmount: 0,
    payments: [],
  }));
};

export const useAddPayment = (invoiceId: string, ownerEmail: string) => {
  return useMutation<any, any, any>(defaultMutationFn);
};
